<template>
<div class="tableList tableList-deep">
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%" border>
      <el-table-column prop="inquiryCode" label="询价编号" :show-overflow-tooltip="true" min-width="50%"></el-table-column>
      <el-table-column prop="inquiryDesc" label="询价描述" :show-overflow-tooltip="true" min-width="100%"></el-table-column>
      <el-table-column prop="businessScope" label="经营范围" :show-overflow-tooltip="true" min-width="50%">
        <template #default="scope">
          {{scope.row.businessScope=="SG"?"曙光":scope.row.businessScope=="KK"?"可控":scope.row.businessScope=="TL"?"腾龙":scope.row.businessScope=="NC"?"宁畅":""}}
        </template>
      </el-table-column>
      <el-table-column prop="inquiryType" label="询价类型" :show-overflow-tooltip="true" min-width="60%">
        <template #default="scope">
          {{scope.row.inquiryType=="1"?"单一供应商询价":scope.row.inquiryType=="2"?"多家供应商询价":scope.row.inquiryType=="3"?"普通竞价":scope.row.inquiryType=="4"?"反向竞价":""}}
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="发布日期" :show-overflow-tooltip="true" min-width="60%"></el-table-column>
      <el-table-column prop="createUserName" label="采购员" :show-overflow-tooltip="true" min-width="40%"></el-table-column>
      <el-table-column prop="status" label="状态" :show-overflow-tooltip="true" min-width="45%">
        <!-- <template #default="scope">
          {{scope.row.status=="0"?"草稿":scope.row.status=="1"?"待报价":scope.row.status=="2"?"待审批":scope.row.status=="3"?"待处理":scope.row.status=="4"?"已结束":scope.row.status=="5"?"废弃":scope.row.status=="6"?"截止报价":""}}
        </template> -->
        <template #default="scope">
          <div class="dotlab"><div class="dot" :style="{background:getColor(scope.row)}"></div>
          {{scope.row.status=="0"?"草稿":scope.row.status=="1"?"待报价":scope.row.status=="2"?"待审批":scope.row.status=="3"?"待处理":scope.row.status=="4"?"已结束":scope.row.status=="5"?"废弃":scope.row.status=="6"?"截止报价":""}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="50%">
        <template #default="scope">
          <template v-if="scope.row.status == 1">
            <el-button @click="Abandoned(scope.row)" type="text" size="small">废弃</el-button>
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 2">
            <el-button @click="Abandoned(scope.row)" type="text" size="small">废弃</el-button>
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 3">
            <el-button @click="Abandoned(scope.row)" type="text" size="small">废弃</el-button>
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 4">
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 5">
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 6">
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
          <template v-if="scope.row.status == 0">
            <el-button type="text" @click="Edit(scope.row)" size="small">编辑</el-button>
            <el-button type="text" @click="See(scope.row)" size="small">查看</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
// import { requestForm } from '@/assets/js/http.js'
import md5 from 'js-md5'
import { request } from '@/assets/js/http.js'
export default {
  name: 'InquiryAndQuotationTab',
  props: ['tableField', 'tableData'],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    getColor (data) {
      return '#' + md5(data.status + '').substring(0, 6)
    },
    // 废弃
    AbandonedData (row) {
      const obj = {
        id: row.id,
        status: 5
      }
      request('/api/inquiry/discard', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.$parent.$parent.InquiryList()
        }
      })
    },
    // 废弃弹框
    Abandoned (row) {
      this.$confirm('确认要废弃吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.AbandonedData(row)
      })
      // .catch(() => {
      //   this.$message({
      //     showClose: true,
      //     type: 'info',
      //     message: '已取消'
      //   })
      // })
    },
    // 查看
    See (row) {
      this.$router.push({
        path: '/Invitation/IssueDetails',
        query: {
          id: row.id
        }
      })
    },
    // 编辑
    Edit (row) {
      this.$router.push({
        path: '/Invitation/IssueInquiry',
        query: {
          id: row.id
        }
      })
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
  .tableList-deep{
    .table .el-table td{
      padding: 4px 0;
      .cell{
          .dotlab{
            display: flex;
            align-items: center;
          }
          .dot{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
    }
    :deep(.el-overlay){
      justify-content: center;
      align-items: center;
      display: flex;
    }
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-dialog){
      width: 500px !important;
      margin: 0 !important;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    :deep(.el-pagination){
      text-align: center;
      padding: 0;
    }
  }
  .role-cont{
    padding: 50px 0;
    text-align: center;
  }
  .modify .role-cont{
    padding: 50px 60px;
  }
</style>
